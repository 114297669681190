import React, { useEffect, useState } from "react";
import API from "../../Api/api";
import SubscriptionRzpPayment from "./SubscriptionRzpPayment";
import SubsStripePayment from "./SubsStripePayment";
import PaypalSubscriptionPayment from "./SubsPayPalPayment";
import Loader from "../loader";

const SubscriptionPurchase = () => {
  const subsDetails =
    new URLSearchParams(window.location.search).get("subsDetails") || undefined;
  const subscriptionContentDetails = JSON.parse(
    decodeURIComponent(window.atob(subsDetails as string))
  );

  const [userDetails, setUserdata] = useState("");

  const [accessToken, setAccessToken] = useState({ accessToken: "" });
  const [routing, setRouting] = useState({
    color: "",
    gateway: "",
    logoUrl: "",
    publishable_key: "",
    clientName: "",
  });
  const callRoutingConfig = async () => {
    const routingResponse = await API.payment.CallRouting({
      clientId: subscriptionContentDetails.clientId,
      // accessToken: accessToken.accessToken,
    });
    const userData = await API.payment.userDetails({
      clientId: subscriptionContentDetails.clientId,
      accessToken: accessToken.accessToken,
    });
    setUserdata(userData.data);
    if (!API.validators.checkSuccessCode(routingResponse))
      return console.error("Unable To fetch data");

    const searchParams1 = new URLSearchParams(window.location.search);
    const paramGateway = searchParams1.get("gateway");
    if (
      paramGateway?.toUpperCase() === "STRIPE" ||
      paramGateway?.toUpperCase() === "RAZORPAY" ||
      paramGateway?.toUpperCase() === "PAYPAL"
    ) {
      setRouting({
        ...routingResponse?.data,
        gateway: paramGateway?.toUpperCase(),
      });
    } else {
      setRouting(routingResponse?.data);
    }
    console.log(routingResponse);
  };
  const findAccessToken = async () => {
    const authzCode = subscriptionContentDetails?.userToken;
    const resp = await API.payment.refreshTokenForSubscription({
      authzCode: `Bearer ${authzCode}`,
    });
    setAccessToken(resp.data);
  };
  useEffect(() => {
    findAccessToken();
  }, []);
  useEffect(() => {
    if (accessToken.accessToken) callRoutingConfig();
  }, [accessToken.accessToken]);

  return (
    <>
      {routing.gateway ? (
        <>
          {routing.gateway === "RAZORPAY" && (
            <SubscriptionRzpPayment
              accessToken={accessToken.accessToken}
              clientName={routing.clientName}
              userDetails={userDetails}
            />
          )}
          {routing.gateway === "STRIPE" && (
            <SubsStripePayment
            
              subscriptionContentDetails={'dsf'}
            />
          )}
          {routing.gateway === "PAYPAL" && (
            <PaypalSubscriptionPayment
            subscriptionContentDetails={'ds'}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SubscriptionPurchase;
