import React, { useEffect, useState } from "react";
import API from "../../Api/api";
import RzpPayment from "./RzpPayment";
import StripePayment from "./StripePayment";
import PaypalPayment from "./PaypalPayment";
import Loader from "../loader";

const PassContentPurchase = () => {
  const contentPassDetailsParam =
    new URLSearchParams(window.location.search).get("contentPassDetails") ||
    undefined;
  const contentPassDetails = JSON.parse(
    window.atob(contentPassDetailsParam as any)
  );
  useEffect(() => {
    document.title = contentPassDetails.paymentDocumentTitle
      ? contentPassDetails.paymentDocumentTitle
      : "Payments";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      //@ts-ignore
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    //@ts-ignore
    link.href = contentPassDetails.brandLogoUrl;
  }, []);

  const [accessToken, setAccessToken] = useState({
    refreshToken: "",
    accessToken: "",
  });
  const [userDetails, setUserdata] = useState("");
  const [routing, setRouting] = useState({
    color: "",
    gateway: "",
    logoUrl: "",
    publishable_key: "",
    clientName: "",
  });
  const searchParams = new URLSearchParams(window.location.search);
  const callRoutingConfig = async () => {
    const routingResponse = await API.payment.CallRouting({
      clientId: contentPassDetails.clientId,
      // accessToken: accessToken.accessToken,
    });
    const userData = await API.payment.userDetails({
      clientId: contentPassDetails.clientId,
      accessToken: accessToken.accessToken,
    });
    setUserdata(userData.data);
    if (!API.validators.checkSuccessCode(routingResponse))
      return console.error("Unable To fetch data");
    setRouting(routingResponse?.data);
  };
  const findAccessToken = async () => {
    const authzCode = searchParams.get("authorizationCode");
    const rid = contentPassDetails.rid;
    const resp = await API.payment.authorizationCode({
      clientId: contentPassDetails.clientId,
      authzCode: `Bearer ${authzCode}`,
      rid: rid,
    });
    setAccessToken(resp.data);
    return await resp.data;
  };
  useEffect(() => {
    findAccessToken();
  }, []);
  useEffect(() => {
    if (accessToken.accessToken) callRoutingConfig();
  }, [accessToken]);
  return (
    <>
      {routing.gateway ? (
        <>
          {routing.gateway === "RAZORPAY" && (
            <RzpPayment
              accessToken={accessToken.accessToken}
              clientName={routing.clientName}
              userDetails={userDetails}
            />
          )}
          {routing.gateway === "STRIPE" && (
            <StripePayment
              accessToken={accessToken.accessToken}
              publishableKey={routing.publishable_key}
            />
          )}
          {routing.gateway === "PAYPAL" && (
            <PaypalPayment
              accessToken={accessToken.accessToken}
              publishableKey={routing.publishable_key}
              isContentPass={true}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PassContentPurchase;
