import React from "react";
import style from "./checkout.module.scss";
import CommonModal from "../../UI/CommonModal/CommonModal";
import { ReactComponent as AddPlusIcon } from "../../assets/Add_plus_icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete_icon.svg";
import { ReactComponent as PencilIcon } from "../../assets/Pencil_icon.svg";
import { Input } from "antd";

export const SavedAddress = ({
  setIsModalOpen,
  isModalOpen,
  userDetails,
  setIndexOfShipingAddress,
  indexOfShipingAddress,
  setUserDetails,
}: {
  setIsModalOpen: any;
  isModalOpen: any;
  userDetails: any;
  setIndexOfShipingAddress: any;
  indexOfShipingAddress: number;
  setUserDetails: any;
}) => {
  return (
    <CommonModal
      isModalVisible={() =>
        setIsModalOpen({ ...isModalOpen, savedAddressModal: false })
      }
      visible={isModalOpen?.savedAddressModal}
      centered
    >
      {userDetails?._id && (
        <div style={{ padding: "20px" }}>
          <div className={style["add-card-heading"]}>Saved Address</div>
          <div style={{ marginTop: "16px" }}>
            <div
              className={style["add-saved-address"]}
              onClick={() =>
                setIsModalOpen({ ...isModalOpen, addressModal: true })
              }
            >
              <AddPlusIcon />
              <div>Add Address</div>
            </div>
            <div className={style["address-list"]}>
              {userDetails?.shippingAddress?.length > 0 ? (
                userDetails?.shippingAddress.map(
                  (address: any, index: number) => (
                    <div
                      onClick={() => {
                        setIndexOfShipingAddress(index);
                      }}
                      className={style["select-address-container"]}
                      style={{
                        border:
                          index === indexOfShipingAddress
                            ? "1px solid black"
                            : "",
                      }}
                    >
                      <div className={style["flex-justify-space-between"]}>
                        <div
                          className={style["flex-center"]}
                          style={{ gap: "10px" }}
                        >
                          <div className={style["address-name"]}>
                            {address.name}
                          </div>
                          <PencilIcon style={{ cursor: "pointer" }} />
                        </div>
                        <DeleteIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setUserDetails({
                              ...userDetails,
                              shippingAddress: [
                                ...userDetails?.shippingAddress.slice(0, index),
                                ...userDetails?.shippingAddress.slice(
                                  index + 1
                                ),
                              ],
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        className={style["address"]}
                        style={{ marginTop: "10px" }}
                      >
                        {address.area}
                        {address.state}
                        {address.country}
                        {address.pincode}
                      </div>
                    </div>
                  )
                )
              ) : (
                <div>No shipping addresses available.</div>
              )}
            </div>
          </div>

          <div className={style["button-container"]}>
            <button
              onClick={() => {
                setIsModalOpen({ ...isModalOpen, savedAddressModal: false });
              }}
              className={style["discard-btn"]}
            >
              Discard
            </button>
            <button
              onClick={() => {
                setIsModalOpen({ ...isModalOpen, savedAddressModal: false });
              }}
              className={style["save-btn"]}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </CommonModal>
  );
};
