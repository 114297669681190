const retryMs = [10, 100, 500, 1000, 5000];

export async function getElementByIdDunned(elementId, i = 0) {
  const element = document.getElementById(elementId);
  if (!element) {
    if (i >= retryMs.length) return null;
    await new Promise((resolve) => setTimeout(resolve, retryMs[i]));
    return getElementByIdDunned(elementId, i + 1);
  }
  return element;
}

export async function CSCOpenUserDetailsPage(clientId) {
  console.log("function called from sdk");
  const refreshToken = localStorage.getItem("refreshToken");
  // onSuccess(accessToken);

  //creating new iframe in
  let wrappingElement = await getElementByIdDunned("users");

  // const clinetConfig = JSON.parse(
  //   window.localStorage.getItem(localStorageVars.CLIENT_CONFIG)
  // );

  wrappingElement.style.display = "block";
  const iframe = document.createElement("iframe");
  iframe.setAttribute("title", "user-Details");
  iframe.style.position = "fixed";
  iframe.style.width = "100%";
  iframe.style.height = "100%";
  iframe.style.zIndex = "10000";
  iframe.style.top = "0px";
  iframe.style.border = "none";
  iframe.style.left = "0px";
  iframe.style.background = "white";

  const ssoUrl = localStorage.getItem("ssoRedirectUrl") || undefined;
  if (!ssoUrl) {
    console.error("ssoUrl is not present");
    return;
  }
  const y = new URL(ssoUrl).origin;

  iframe.setAttribute(
    "src",
    `${y}/secondarydetails?refreshToken=${refreshToken}&clientId=${clientId}&redirectUrl=${window.location.href}}&secondaryDetails=true`
  );

  let fullpageDiv = document.createElement("div");
  fullpageDiv.appendChild(iframe);
  wrappingElement.append(fullpageDiv);
}
