export const Country_flag_json = [
  { name: "Afghanistan", flag: "🇦🇫", code: "AF", dail_code: "+93" },
  { name: "Åland Islands", flag: "🇦🇽", code: "AX", dail_code: "+358" },
  { name: "Albania", flag: "🇦🇱", code: "AL", dail_code: "+355" },
  { name: "Algeria", flag: "🇩🇿", code: "DZ", dail_code: "+213" },
  { name: "American Samoa", flag: "🇦🇸", code: "AS", dail_code: "+1684" },
  { name: "Andorra", flag: "🇦🇩", code: "AD", dail_code: "+376" },
  { name: "Angola", flag: "🇦🇴", code: "AO", dail_code: "+244" },
  { name: "Anguilla", flag: "🇦🇮", code: "AI", dail_code: "+1264" },
  { name: "Antarctica", flag: "🇦🇶", code: "AQ", dail_code: "+672" },
  { name: "Antigua", flag: "🇦🇬", code: "AG", dail_code: "+1268" },
  { name: "Argentina", flag: "🇦🇷", code: "AR", dail_code: "+54" },
  { name: "Armenia", flag: "🇦🇲", code: "AM", dail_code: "+374" },
  { name: "Aruba", flag: "🇦🇼", code: "AW", dail_code: "+297" },
  { name: "Australia", flag: "🇦🇺", code: "AU", dail_code: "+61" },
  { name: "Austria", flag: "🇦🇹", code: "AT", dail_code: "+43" },
  { name: "Azerbaijan", flag: "🇦🇿", code: "AZ", dail_code: "+994" },
  { name: "Bahamas", flag: "🇧🇸", code: "BS", dail_code: "+1242" },
  { name: "Bahrain", flag: "🇧🇭", code: "BH", dail_code: "+973" },
  { name: "Bangladesh", flag: "🇧🇩", code: "BD", dail_code: "+880" },
  { name: "Barbados", flag: "🇧🇧", code: "BB", dail_code: "+1246" },
  { name: "Belarus", flag: "🇧🇾", code: "BY", dail_code: "+375" },
  { name: "Belgium", flag: "🇧🇪", code: "BE", dail_code: "+32" },
  { name: "Belize", flag: "🇧🇿", code: "BZ", dail_code: "+501" },
  { name: "Benin", flag: "🇧🇯", code: "BJ", dail_code: "+229" },
  { name: "Bermuda", flag: "🇧🇲", code: "BM", dail_code: "+1441" },
  { name: "Bhutan", flag: "🇧🇹", code: "BT", dail_code: "+975" },
  { name: "Bolivia", flag: "🇧🇴", code: "BO", dail_code: "+591" },
  { name: "Bosnia", flag: "🇧🇦", code: "BA", dail_code: "+387" },
  { name: "Botswana", flag: "🇧🇼", code: "BW", dail_code: "+267" },
  { name: "Bouvet Island", flag: "🇧🇻", code: "BV", dail_code: "+47" },
  { name: "Brazil", flag: "🇧🇷", code: "BR", dail_code: "+55" },
  { name: "British Indian", flag: "🇮🇴", code: "IO", dail_code: "+246" },
  { name: "Brunei Darussalam", flag: "🇧🇳", code: "BN", dail_code: "+673" },
  { name: "Bulgaria", flag: "🇧🇬", code: "BG", dail_code: "+359" },
  { name: "Burkina Faso", flag: "🇧🇫", code: "BF", dail_code: "+226" },
  { name: "Burundi", flag: "🇧🇮", code: "BI", dail_code: "+257" },
  { name: "Cambodia", flag: "🇰🇭", code: "KH", dail_code: "+855" },
  { name: "Cameroon", flag: "🇨🇲", code: "CM", dail_code: "+237" },
  { name: "Canada", flag: "🇨🇦", code: "CA", dail_code: "+1" },
  { name: "Cape Verde", flag: "🇨🇻", code: "CV", dail_code: "+238" },
  { name: "Cayman Islands", flag: "🇰🇾", code: "KY", dail_code: "+345" },
  { name: "Central African", flag: "🇨🇫", code: "CF", dail_code: "+236" },
  { name: "Chad", flag: "🇹🇩", code: "TD", dail_code: "+235" },
  { name: "Chile", flag: "🇨🇱", code: "CL", dail_code: "+56" },
  { name: "China", flag: "🇨🇳", code: "CN", dail_code: "+86" },
  { name: "Christmas Island", flag: "🇨🇽", code: "CX", dail_code: "+61" },
  { name: "Cocos", flag: "🇨🇨", code: "CC", dail_code: "+61" },
  { name: "Colombia", flag: "🇨🇴", code: "CO", dail_code: "+57" },
  { name: "Comoros", flag: "🇰🇲", code: "KM", dail_code: "+269" },
  { name: "Congo", flag: "🇨🇬", code: "CG", dail_code: "+242" },
  { name: "Congo", flag: "🇨🇩", code: "CD", dail_code: "+243" },
  { name: "Cook Islands", flag: "🇨🇰", code: "CK", dail_code: "+682" },
  { name: "Costa Rica", flag: "🇨🇷", code: "CR", dail_code: "+506" },
  { name: "Cote d'Ivoire", flag: "🇨🇮", code: "CI", dail_code: "+225" },
  { name: "Croatia", flag: "🇭🇷", code: "HR", dail_code: "+385" },
  { name: "Cuba", flag: "🇨🇺", code: "CU", dail_code: "+53" },
  { name: "Cyprus", flag: "🇨🇾", code: "CY", dail_code: "+357" },
  { name: "Czech", flag: "🇨🇿", code: "CZ", dail_code: "+420" },
  { name: "Denmark", flag: "🇩🇰", code: "DK", dail_code: "+45" },
  { name: "Djibouti", flag: "🇩🇯", code: "DJ", dail_code: "+253" },
  { name: "Dominica", flag: "🇩🇲", code: "DM", dail_code: "+1767" },
  { name: "Dominican", flag: "🇩🇴", code: "DO", dail_code: "+1849" },
  { name: "Ecuador", flag: "🇪🇨", code: "EC", dail_code: "+593" },
  { name: "Egypt", flag: "🇪🇬", code: "EG", dail_code: "+20" },
  { name: "El Salvador", flag: "🇸🇻", code: "SV", dail_code: "+503" },
  { name: "Equatorial Guinea", flag: "🇬🇶", code: "GQ", dail_code: "+240" },
  { name: "Eritrea", flag: "🇪🇷", code: "ER", dail_code: "+291" },
  { name: "Estonia", flag: "🇪🇪", code: "EE", dail_code: "+372" },
  { name: "Ethiopia", flag: "🇪🇹", code: "ET", dail_code: "+251" },
  { name: "Falkland Islands", flag: "🇫🇰", code: "FK", dail_code: "+500" },
  { name: "Faroe Islands", flag: "🇫🇴", code: "FO", dail_code: "+298" },
  { name: "Fiji", flag: "🇫🇯", code: "FJ", dail_code: "+679" },
  { name: "Finland", flag: "🇫🇮", code: "FI", dail_code: "+358" },
  { name: "France", flag: "🇫🇷", code: "FR", dail_code: "+33" },
  { name: "French Guiana", flag: "🇬🇫", code: "GF", dail_code: "+594" },
  { name: "French Polynesia", flag: "🇵🇫", code: "PF", dail_code: "+689" },
  { name: "French Southern", flag: "🇹🇫", code: "TF", dail_code: "+262" },
  { name: "Gabon", flag: "🇬🇦", code: "GA", dail_code: "+241" },
  { name: "Gambia", flag: "🇬🇲", code: "GM", dail_code: "+220" },
  { name: "Georgia", flag: "🇬🇪", code: "GE", dail_code: "+995" },
  { name: "Germany", flag: "🇩🇪", code: "DE", dail_code: "+49" },
  { name: "Ghana", flag: "🇬🇭", code: "GH", dail_code: "+233" },
  { name: "Gibraltar", flag: "🇬🇮", code: "GI", dail_code: "+350" },
  { name: "Greece", flag: "🇬🇷", code: "GR", dail_code: "+30" },
  { name: "Greenland", flag: "🇬🇱", code: "GL", dail_code: "+299" },
  { name: "Grenada", flag: "🇬🇩", code: "GD", dail_code: "+1473" },
  { name: "Guadeloupe", flag: "🇬🇵", code: "GP", dail_code: "+590" },
  { name: "Guam", flag: "🇬🇺", code: "GU", dail_code: "+1671" },
  { name: "Guatemala", flag: "🇬🇹", code: "GT", dail_code: "+502" },
  { name: "Guernsey", flag: "🇬🇬", code: "GG", dail_code: "+44" },
  { name: "Guinea", flag: "🇬🇳", code: "GN", dail_code: "+224" },
  { name: "Guinea-Bissau", flag: "🇬🇼", code: "GW", dail_code: "+245" },
  { name: "Guyana", flag: "🇬🇾", code: "GY", dail_code: "+592" },
  { name: "Haiti", flag: "🇭🇹", code: "HT", dail_code: "+509" },
  { name: "Heard Island", flag: "🇭🇲", code: "HM", dail_code: "+672" },
  { name: "Holy See", flag: "🇻🇦", code: "VA", dail_code: "+379" },
  { name: "Honduras", flag: "🇭🇳", code: "HN", dail_code: "+504" },
  { name: "Hong Kong", flag: "🇭🇰", code: "HK", dail_code: "+852" },
  { name: "Hungary", flag: "🇭🇺", code: "HU", dail_code: "+36" },
  { name: "Iceland", flag: "🇮🇸", code: "IS", dail_code: "+354" },
  { name: "India", flag: "🇮🇳", code: "IN", dail_code: "+91" },
  { name: "Indonesia", flag: "🇮🇩", code: "ID", dail_code: "+62" },
  { name: "Iran", flag: "🇮🇷", code: "IR", dail_code: "+98" },
  { name: "Iraq", flag: "🇮🇶", code: "IQ", dail_code: "+964" },
  { name: "Ireland", flag: "🇮🇪", code: "IE", dail_code: "+353" },
  { name: "Isle of Man", flag: "🇮🇲", code: "IM", dail_code: "+44" },
  { name: "Israel", flag: "🇮🇱", code: "IL", dail_code: "+972" },
  { name: "Italy", flag: "🇮🇹", code: "IT", dail_code: "+39" },
  { name: "Jamaica", flag: "🇯🇲", code: "JM", dail_code: "+1876" },
  { name: "Japan", flag: "🇯🇵", code: "JP", dail_code: "+81" },
  { name: "Jersey", flag: "🇯🇪", code: "JE", dail_code: "+44" },
  { name: "Jordan", flag: "🇯🇴", code: "JO", dail_code: "+962" },
  { name: "Kazakhstan", flag: "🇰🇿", code: "KZ", dail_code: "+7" },
  { name: "Kenya", flag: "🇰🇪", code: "KE", dail_code: "+254" },
  { name: "Kiribati", flag: "🇰🇮", code: "KI", dail_code: "+686" },
  { name: "Korea", flag: "🇰🇵", code: "KP", dail_code: "+850" },
  { name: "Korea", flag: "🇰🇷", code: "KR", dail_code: "+82" },
  { name: "Kosovo", flag: "🇽🇰", code: "XK", dail_code: "+383" },
  { name: "Kuwait", flag: "🇰🇼", code: "KW", dail_code: "+965" },
  { name: "Kyrgyzstan", flag: "🇰🇬", code: "KG", dail_code: "+996" },
  { name: "Laos", flag: "🇱🇦", code: "LA", dail_code: "+856" },
  { name: "Latvia", flag: "🇱🇻", code: "LV", dail_code: "+371" },
  { name: "Lebanon", flag: "🇱🇧", code: "LB", dail_code: "+961" },
  { name: "Lesotho", flag: "🇱🇸", code: "LS", dail_code: "+266" },
  { name: "Liberia", flag: "🇱🇷", code: "LR", dail_code: "+231" },
  { name: "Libyan Arab Jamahiriya", flag: "🇱🇾", code: "LY", dail_code: "+218" },
  { name: "Liechtenstein", flag: "🇱🇮", code: "LI", dail_code: "+423" },
  { name: "Lithuania", flag: "🇱🇹", code: "LT", dail_code: "+370" },
  { name: "Luxembourg", flag: "🇱🇺", code: "LU", dail_code: "+352" },
  { name: "Macao", flag: "🇲🇴", code: "MO", dail_code: "+853" },
  { name: "Macedonia", flag: "🇲🇰", code: "MK", dail_code: "+389" },
  { name: "Madagascar", flag: "🇲🇬", code: "MG", dail_code: "+261" },
  { name: "Malawi", flag: "🇲🇼", code: "MW", dail_code: "+265" },
  { name: "Malaysia", flag: "🇲🇾", code: "MY", dail_code: "+60" },
  { name: "Maldives", flag: "🇲🇻", code: "MV", dail_code: "+960" },
  { name: "Mali", flag: "🇲🇱", code: "ML", dail_code: "+223" },
  { name: "Malta", flag: "🇲🇹", code: "MT", dail_code: "+356" },
  { name: "Marshall Islands", flag: "🇲🇭", code: "MH", dail_code: "+692" },
  { name: "Martinique", flag: "🇲🇶", code: "MQ", dail_code: "+596" },
  { name: "Mauritania", flag: "🇲🇷", code: "MR", dail_code: "+222" },
  { name: "Mauritius", flag: "🇲🇺", code: "MU", dail_code: "+230" },
  { name: "Mayotte", flag: "🇾🇹", code: "YT", dail_code: "+262" },
  { name: "Mexico", flag: "🇲🇽", code: "MX", dail_code: "+52" },
  { name: "Micronesia", flag: "🇫🇲", code: "FM", dail_code: "+691" },
  { name: "Moldova", flag: "🇲🇩", code: "MD", dail_code: "+373" },
  { name: "Monaco", flag: "🇲🇨", code: "MC", dail_code: "+377" },
  { name: "Mongolia", flag: "🇲🇳", code: "MN", dail_code: "+976" },
  { name: "Montenegro", flag: "🇲🇪", code: "ME", dail_code: "+382" },
  { name: "Montserrat", flag: "🇲🇸", code: "MS", dail_code: "+1664" },
  { name: "Morocco", flag: "🇲🇦", code: "MA", dail_code: "+212" },
  { name: "Mozambique", flag: "🇲🇿", code: "MZ", dail_code: "+258" },
  { name: "Myanmar", flag: "🇲🇲", code: "MM", dail_code: "+95" },
  { name: "Namibia", flag: "🇳🇦", code: "NA", dail_code: "+264" },
  { name: "Nauru", flag: "🇳🇷", code: "NR", dail_code: "+674" },
  { name: "Nepal", flag: "🇳🇵", code: "NP", dail_code: "+977" },
  { name: "Netherlands", flag: "🇳🇱", code: "NL", dail_code: "+31" },
  { name: "Netherlands Antilles", flag: "", code: "AN", dail_code: "+599" },
  { name: "New Caledonia", flag: "🇳🇨", code: "NC", dail_code: "+687" },
  { name: "New Zealand", flag: "🇳🇿", code: "NZ", dail_code: "+64" },
  { name: "Nicaragua", flag: "🇳🇮", code: "NI", dail_code: "+505" },
  { name: "Niger", flag: "🇳🇪", code: "NE", dail_code: "+227" },
  { name: "Nigeria", flag: "🇳🇬", code: "NG", dail_code: "+234" },
  { name: "Niue", flag: "🇳🇺", code: "NU", dail_code: "+683" },
  { name: "Norfolk Island", flag: "🇳🇫", code: "NF", dail_code: "+672" },
  { name: "Northern Mariana Islands", flag: "🇲🇵", code: "MP", dail_code: "+1670" },
  { name: "Norway", flag: "🇳🇴", code: "NO", dail_code: "+47" },
  { name: "Oman", flag: "🇴🇲", code: "OM", dail_code: "+968" },
  { name: "Pakistan", flag: "🇵🇰", code: "PK", dail_code: "+92" },
  { name: "Palau", flag: "🇵🇼", code: "PW", dail_code: "+680" },
  { name: "Palestinian Territory", flag: "🇵🇸", code: "PS", dail_code: "+970" },
  { name: "Panama", flag: "🇵🇦", code: "PA", dail_code: "+507" },
  { name: "Papua New Guinea", flag: "🇵🇬", code: "PG", dail_code: "+675" },
  { name: "Paraguay", flag: "🇵🇾", code: "PY", dail_code: "+595" },
  { name: "Peru", flag: "🇵🇪", code: "PE", dail_code: "+51" },
  { name: "Philippines", flag: "🇵🇭", code: "PH", dail_code: "+63" },
  { name: "Pitcairn", flag: "🇵🇳", code: "PN", dail_code: "+64" },
  { name: "Poland", flag: "🇵🇱", code: "PL", dail_code: "+48" },
  { name: "Portugal", flag: "🇵🇹", code: "PT", dail_code: "+351" },
  { name: "Puerto Rico", flag: "🇵🇷", code: "PR", dail_code: "+1939" },
  { name: "Qatar", flag: "🇶🇦", code: "QA", dail_code: "+974" },
  { name: "Romania", flag: "🇷🇴", code: "RO", dail_code: "+40" },
  { name: "Russia", flag: "🇷🇺", code: "RU", dail_code: "+7" },
  { name: "Rwanda", flag: "🇷🇼", code: "RW", dail_code: "+250" },
  { name: "Reunion", flag: "🇷🇪", code: "RE", dail_code: "+262" },
  { name: "Saint Barthelemy", flag: "🇧🇱", code: "BL", dail_code: "+590" },
  { name: "Saint Helena ", flag: "🇸🇭", code: "SH", dail_code: "+290" },
  { name: "Saint Kitts ", flag: "🇰🇳", code: "KN", dail_code: "+1869" },
  { name: "Saint Lucia", flag: "🇱🇨", code: "LC", dail_code: "+1758" },
  { name: "Saint Martin", flag: "🇲🇫", code: "MF", dail_code: "+590" },
  { name: "Saint Pierre and Miquelon", flag: "🇵🇲", code: "PM", dail_code: "+508" },
  { name: "Saint Vincent", flag: "🇻🇨", code: "VC", dail_code: "+1784" },
  { name: "Samoa", flag: "🇼🇸", code: "WS", dail_code: "+685" },
  { name: "San Marino", flag: "🇸🇲", code: "SM", dail_code: "+378" },
  { name: "Sao Tome", flag: "🇸🇹", code: "ST", dail_code: "+239" },
  { name: "Saudi Arabia", flag: "🇸🇦", code: "SA", dail_code: "+966" },
  { name: "Senegal", flag: "🇸🇳", code: "SN", dail_code: "+221" },
  { name: "Serbia", flag: "🇷🇸", code: "RS", dail_code: "+381" },
  { name: "Seychelles", flag: "🇸🇨", code: "SC", dail_code: "+248" },
  { name: "Sierra Leone", flag: "🇸🇱", code: "SL", dail_code: "+232" },
  { name: "Singapore", flag: "🇸🇬", code: "SG", dail_code: "+65" },
  { name: "Slovakia", flag: "🇸🇰", code: "SK", dail_code: "+421" },
  { name: "Slovenia", flag: "🇸🇮", code: "SI", dail_code: "+386" },
  { name: "Solomon Islands", flag: "🇸🇧", code: "SB", dail_code: "+677" },
  { name: "Somalia", flag: "🇸🇴", code: "SO", dail_code: "+252" },
  { name: "South Africa", flag: "🇿🇦", code: "ZA", dail_code: "+27" },
  { name: "South Sudan", flag: "🇸🇸", code: "SS", dail_code: "+211" },
  { name: "South Georgia", flag: "🇬🇸", code: "GS", dail_code: "+500" },
  { name: "Spain", flag: "🇪🇸", code: "ES", dail_code: "+34" },
  { name: "Sri Lanka", flag: "🇱🇰", code: "LK", dail_code: "+94" },
  { name: "Sudan", flag: "🇸🇩", code: "SD", dail_code: "+249" },
  { name: "Suriname", flag: "🇸🇷", code: "SR", dail_code: "+597" },
  { name: "Svalbard", flag: "🇸🇯", code: "SJ", dail_code: "+47" },
  { name: "Eswatini", flag: "🇸🇿", code: "SZ", dail_code: "+268" },
  { name: "Sweden", flag: "🇸🇪", code: "SE", dail_code: "+46" },
  { name: "Switzerland", flag: "🇨🇭", code: "CH", dail_code: "+41" },
  { name: "Syrian Arab", flag: "🇸🇾", code: "SY", dail_code: "+963" },
  { name: "Taiwan", flag: "🇹🇼", code: "TW", dail_code: "+886" },
  { name: "Tajikistan", flag: "🇹🇯", code: "TJ", dail_code: "+992" },
  { name: "Tanzania", flag: "🇹🇿", code: "TZ", dail_code: "+255" },
  { name: "Thailand", flag: "🇹🇭", code: "TH", dail_code: "+66" },
  { name: "Timor-Leste", flag: "🇹🇱", code: "TL", dail_code: "+670" },
  { name: "Togo", flag: "🇹🇬", code: "TG", dail_code: "+228" },
  { name: "Tokelau", flag: "🇹🇰", code: "TK", dail_code: "+690" },
  { name: "Tonga", flag: "🇹🇴", code: "TO", dail_code: "+676" },
  { name: "Trinidad", flag: "🇹🇹", code: "TT", dail_code: "+1868" },
  { name: "Tunisia", flag: "🇹🇳", code: "TN", dail_code: "+216" },
  { name: "Turkey", flag: "🇹🇷", code: "TR", dail_code: "+90" },
  { name: "Turkmenistan", flag: "🇹🇲", code: "TM", dail_code: "+993" },
  { name: "Turks", flag: "🇹🇨", code: "TC", dail_code: "+1649" },
  { name: "Tuvalu", flag: "🇹🇻", code: "TV", dail_code: "+688" },
  { name: "Uganda", flag: "🇺🇬", code: "UG", dail_code: "+256" },
  { name: "Ukraine", flag: "🇺🇦", code: "UA", dail_code: "+380" },
  { name: "United Arab Emirates", flag: "🇦🇪", code: "AE", dail_code: "+971" },
  { name: "United Kingdom", flag: "🇬🇧", code: "GB", dail_code: "+44" },
  { name: "United States", flag: "🇺🇸", code: "US", dail_code: "+1" },
  { name: "Uruguay", flag: "🇺🇾", code: "UY", dail_code: "+598" },
  { name: "Uzbekistan", flag: "🇺🇿", code: "UZ", dail_code: "+998" },
  { name: "Vanuatu", flag: "🇻🇺", code: "VU", dail_code: "+678" },
  { name: "Venezuela", flag: "🇻🇪", code: "VE", dail_code: "+58" },
  { name: "Vietnam", flag: "🇻🇳", code: "VN", dail_code: "+84" },
  { name: "Virgin Islands", flag: "🇻🇬", code: "VG", dail_code: "+1284" },
  { name: "Virgin Islands", flag: "🇻🇮", code: "VI", dail_code: "+1340" },
  { name: "Wallis", flag: "🇼🇫", code: "WF", dail_code: "+681" },
  { name: "Yemen", flag: "🇾🇪", code: "YE", dail_code: "+967" },
  { name: "Zambia", flag: "🇿🇲", code: "ZM", dail_code: "+260" },
  { name: "Zimbabwe", flag: "🇿🇼", code: "ZW", dail_code: "+263" }
]
