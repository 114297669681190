import React, { useEffect } from "react";
// import internal from "stream";

const StepUpForm = ({ stepUpToken, transactionId, cardToken }) => {
  useEffect(() => {
    const form = document.querySelector("#step-up-form");
    if (form) form.submit();
  }, []);

  // Check iframe content every second (1000ms)

  const accessToken = localStorage.getItem("accessToken");
  const arrayToken = accessToken.split(".");
  const tokenPayload = JSON.parse(atob(arrayToken[1]));
  const searchParams = new URLSearchParams(window.location.search);
  const clientId = searchParams.get("clientId");
  // localStorage.setItem('userId',tokenPayload.sub)
  const obj = {
    clientId: clientId,
    internalTid: transactionId,
    userId: tokenPayload.sub,
    tokenId: cardToken,
  };
  return (
    <div>
      <iframe
        name="step-up-iframe"
        width="400"
        height="400"
        title="Step Up Iframe"
      ></iframe>
      <form
        id="step-up-form"
        target="step-up-iframe"
        method="post"
        action="https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp"
      >
        <input type="hidden" name="JWT" value={stepUpToken} />
        <input type="hidden" name="MD" value={JSON.stringify(obj)} />
      </form>
    </div>
  );
};

export default StepUpForm;
