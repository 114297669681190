import { useContext, useEffect, useRef, useState } from "react";
import API from "../../Api/api";
import Loader from "../loader";
import style from "./alreadypurchased.module.scss";
import {
  antdMessageError,
  checkoutSettingsDefaultData,
} from "../Checkout/utills";
import { CSCOpenUserDetailsPage } from "./Utills";
import { Footer } from "../Checkout/Footer";
import { MyContext } from "../../App";

export function razorPayRedirectToContent(message?: string, userId?: string) {
  // for android

  try {
    // @ts-ignore
    redirectionHandler?.razorPayRedirectToContent(message, userId);
  } catch (error) {
    console.warn("did not find android redirect handler");
  }

  // for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.razorPayRedirectToContent.postMessage(
      JSON.stringify({ message, userId })
    );
  } catch (error) {
    console.warn("did not find ios redirect handler");
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler(
      "razorPayRedirectToContent",
      message,
      userId
    );
  } catch (error) {
    console.warn("did not find flutter 2 handler");
  }

  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ razorPayRedirectToContent: message, userId })
    );
  } catch (error) {
    console.warn("did not find ReactNativeWebView handler");
  }
}

const ThankyouNew = () => {
  const [checkingStatus, setCheckingStatus] = useState(true);
  const [status, setStatus] = useState("");
  const hitsRef = useRef(0);
  const [showUserDetails, setShowUserDetails] = useState(true);
  const searchParams = new URLSearchParams(window.location.search);
  const transactionId = searchParams.get("transactionId");
  const renewalId = searchParams.get("renewalId");
  const accessToken = localStorage.getItem("accessToken") || "";
  const arrayToken = accessToken.split(".");
  const tokenPayload = JSON.parse(atob(arrayToken[1]));
  const { buttonBackground, buttonFontColor,brandLogoUrl, tnc, privPolicy } = useContext(MyContext);

  useEffect(() => {
    const x = new URLSearchParams(window.location.href).get("secondaryDetails");
    const clientId = new URLSearchParams(window.location.href).get("clientId");
    window.addEventListener("message", (message) => {
      if (message.data.source === "update-from-user-thank") {
        setShowUserDetails(false);
        redirectToContent();
      }
      if (message.data.source === "userDetailAction") {
        setShowUserDetails(false);
      }
    });

    if (x === "true" && status === "COMPLETE") {
      CSCOpenUserDetailsPage(clientId);
    }
  }, [status]);
  const redirectToContent = async () => {
    razorPayRedirectToContent("SUBS_REDIRECT_TO_CONTENT", tokenPayload.sub);
    const searchParams = new URLSearchParams(window.location.search);
    let rid = searchParams.get("rid");
    if (rid === "null") {
      rid = null;
    }

    const redirectUrlResponse = await API.client.RedirectUserToContent({
      clientId: searchParams.get("clientId") || "",
      clientContentId: searchParams.get("clientContentId") || "",
      pageType: rid ? "AMP" : "STANDARD",
    });
    if (!API.validators.checkSuccessCode(redirectUrlResponse)) {
      return alert("Failed to load redirect URL");
    } else {
      const ssoUrl = redirectUrlResponse?.data?.ssoRedirectUrl;
      const redirecturl = new URL(
        decodeURIComponent(redirectUrlResponse.data?.redirectUrl)
      );
      redirecturl.searchParams.set(
        "transactionId",
        searchParams.get("transactionId") || ""
      );
      if (searchParams.get("thirdstep") === "POPUP") {
        redirecturl.searchParams.append("SHOWPOP", "true");
        redirecturl.searchParams.append(
          "popUpId",
          searchParams.get("popUpId") || ""
        );
        redirecturl.searchParams.append("skip", "true");
      }
      window.location.href = `${ssoUrl}?redirectUrl=${redirecturl}&clientId=${searchParams.get(
        "clientId"
      )}&anonId=${searchParams.get("anonId")}&transactionId=${searchParams.get(
        "transactionId"
      )}&contentId=${searchParams.get(
        "clientContentId"
      )}&paywallId=${searchParams.get("paywallId")}`;
    }
    // }
  };
  useEffect(() => {
    async function callPopUpFunction(popUpId: string) {
      if (popUpId.length < 15) {
        return;
      }
      const popUpData = await fetch(
        `${process.env.NEXT_PUBLIC_BACKENDV2}client/popup/${popUpId}`,
        {
          method: "GET",
        }
      ).then((res) => res.json());
      //@ts-ignore
      window.CloseFrame = function () {
        //@ts-ignore
        document.getElementById("popUp-container").style.display = "none";
      };

      //@ts-ignore
      window.skip = function () {
        redirectToContent();
      };

      let iframe = document.createElement("iframe");
      const html = popUpData.content;

      iframe.style.border = "none";
      iframe.style.margin = "0px";
      iframe.style.padding = "0px";
      iframe.style.background = "transparent";
      iframe.style.width = "100vw";
      iframe.style.height = "100vh";
      iframe.setAttribute(
        "srcdoc",
        `<html><head>
           
          </head><body>
           ${html}
            </body>
          
            </html> `
      );
      var innerDiv = document.createElement("div");
      innerDiv.className = "inner-div-popUp";
      innerDiv.style.position = "fixed";
      innerDiv.style.left = "0px";
      //@ts-ignore
      innerDiv.style.zIndex = 2147483599;
      innerDiv.style.width = "100vw";
      innerDiv.style.top = "0px";

      innerDiv.style.display = "flex";
      innerDiv.style.justifyContent = "center";
      innerDiv.style.alignItems = "center";
      const wrappingElement = document.createElement("div");
      wrappingElement.setAttribute("id", `popUp-container`);
      document.body.appendChild(wrappingElement);
      wrappingElement.append(innerDiv);
      innerDiv.appendChild(iframe);
    }
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("secondStep") === "POPUP") {
      callPopUpFunction(searchParams.get("popUpId") || "");
    }
  }, []);
  const transitionStatusCheck = async () => {
    const transitionStatus = await API.user.transitionStatus({
      id: transactionId || "",
    });
    if (transitionStatus?.data?.data?.status === "COMPLETE") {
      setStatus("COMPLETE");
      setCheckingStatus(false);
    } else if (transitionStatus?.data?.data?.status === "FAILED") {
      antdMessageError(
        "Transaction Failed. If any amount has been deducted from your account, it will be refunded automatically. Please try again.Transaction Failed. If any amount has been deducted from your account, it will be refunded automatically. Please try again."
      );
      const searchParams = new URLSearchParams(window.location.search);
      let failureUrl = searchParams.get("failureUrl") || "";
      window.location.href = failureUrl;
    } else {
      hitsRef.current += 1;
      if (hitsRef.current <= 4) {
        setTimeout(() => {
          transitionStatusCheck();
        }, 6000);
      }
    }
    //
  };
  const renewalStatusCheck = async () => {
    const renewalStatus = await API.user.renewalStatus({
      id: renewalId as string,
      // accessToken: response?.accessToken as string,
    });
    if (
      renewalStatus?.data?.data?.status === "ACTIVE" ||
      renewalStatus?.data?.data?.status === "COMPLETED"
    ) {
      setCheckingStatus(false);
      setStatus("COMPLETE");
    } else if (renewalStatus?.data?.data?.status === "FAILED") {
      antdMessageError(
        "Transaction Failed. If any amount has been deducted from your account, it will be refunded automatically. Please try again.Transaction Failed. If any amount has been deducted from your account, it will be refunded automatically. Please try again."
      );
      const searchParams = new URLSearchParams(window.location.search);
      let failureUrl = searchParams.get("failureUrl") || "";
      window.location.href = failureUrl;
    } else {
      hitsRef.current += 1;
      if (hitsRef.current <= 4) {
        setTimeout(() => {
          renewalStatusCheck();
        }, 6000);
      }
    }
  };

  useEffect(() => {
    (async () => {
      // await getLoginCustomizeData()
      const searchParams = new URLSearchParams(window.location.search);
      const transactionId = searchParams.get("transactionId");
      const renewalId = searchParams.get("renewalId");
      if (transactionId) {
        transitionStatusCheck();
      }
      if (renewalId) {
        renewalStatusCheck();
      }
    })();
  }, []);

  if (checkingStatus === true) {
    return (
      <div>
        <Loader message="Your payment is being processed. Thank you for your patience." />
      </div>
    );
  }

  return status === "COMPLETE" ? (
    <>
      {showUserDetails && <div id="users"></div>}
      <div className={style["main-container"]}>
        <div className={style["header-logo"]}>
          <img className={style["image-logo"]} src={brandLogoUrl} alt="Logo" />
        </div>
        <div className={style["p-4"]}></div>
        <div className={style["sub-container"]}>
          <div className={style["innner-container"]}>
            <div className={style["thankyou-title"]}>
              "Thank You for Subscribing"
            </div>
            {tokenPayload.email && (
              <div className={style["sub-title"]}>
                Your subscription is active and the details have been mailed to{" "}
                <span
                  style={{
                    color: buttonBackground || '#000000',
                  }}
                >
                  {tokenPayload.email}
                </span>
              </div>
            )}
            <div
              className={style["sub-description"]}
              style={{ marginTop: "20px", marginBottom: "25px" }}
            >
              click to redirect to content
            </div>
            <button
              className={style["submitBtn"]}
              onClick={() => redirectToContent()}
              style={{
                background:
                buttonBackground || "#000000",
                marginTop: "0px",
                border: "0px",
                color: buttonFontColor || "#ffffff",
              }}
            >
              Continue Reading
            </button>
          </div>
        </div>
        <div className={style["footer"]}>
          <Footer termsAndConditions={tnc} privacyAndPolicy={privPolicy} />
        </div>
      </div>
    </>
  ) : (
    <>
      <h1>some error occured</h1>
    </>
  );
};

export default ThankyouNew;
