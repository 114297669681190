import React from 'react';
import style from './checkout.module.scss';

export const Footer = ({termsAndConditions, privacyAndPolicy, contact}: {termsAndConditions?:string, privacyAndPolicy?:string , contact?:string}) => {
  return (
    <div className={style['footer-container']}>
      <div className={style['footer-text']}>Copyright © 2024</div>
      <div className={style['footer-hanldes']}>
        {/* <a className={style['footer-text']} rel="noopener noreferrer">Contact Us</a> */}
        <a className={style['footer-text']} href={contact} rel="noopener noreferrer">Contact Us</a>
        <a className={style['footer-text']} href={termsAndConditions} rel="noopener noreferrer">Privacy Policy</a>
        <a className={style['footer-text']} href={privacyAndPolicy} rel="noopener noreferrer">Terms and Conditions</a>
      </div>
    </div>
  );
};
