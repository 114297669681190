import React, { useEffect, useState } from 'react'
import style from './checkout.module.scss'
import CommonModal from '../../UI/CommonModal/CommonModal'
import { Input } from 'antd'
import API from '../../Api/api'
export const Benifits = ({
  setIsModalOpen,
  isModalOpen,
  benifits
}: {
  setIsModalOpen: any;
  isModalOpen: any;
  benifits:any

}) => {
  console.log(benifits,'sfjkbgiujk')
if(benifits.benefits.length===0){
  return(
    <></>
  )
}
  return (
    <CommonModal
      isModalVisible={() => setIsModalOpen({ ...isModalOpen, benifits: false })}
      visible={isModalOpen?.benifits}
      centered
    >
      <div style={{ padding: '20px' }}>
       {benifits.benefits.map((benefit:string,index:number)=>{
        return(
            <div className={style['add-card-heading']}>{benefit}</div>

        )
       })}
       
      </div>
    </CommonModal>
  )
}