import react, { createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { InitializaPayment } from "./component/initializaPayment";
import Checkout from "./component/Checkout/Checkout";
import { razorPayRedirectToContent } from "./component/utills/mobilehandlers";
// import Thankyou from "./component/Thankyou/Thankyou";
import ThankyouNew from "./component/Thankyou/ThankyouNew";
import { useEffect, useState } from "react";
import API from "./Api/api";
import { antdMessageError } from "./component/Checkout/utills";
export const MyContext = createContext({
  brandLogoUrl: '',
  tnc: '',
  privPolicy: '',
  buttonBackground:'',
  buttonFontColor:''
});
function App() {
  const [loginSetting, setLoginSetting] = useState<any>({});
  const [checkoutSetting,setCheckoutSetting] = useState<any>({});
  useEffect(() => {
    const handlePageShow = (event: any) => {
      if (event.persisted) {
        // Page is restored from bfcache
        // Perform any action needed like re-fetching data or forcing a re-render
        console.log('Page was restored from bfcache');
        // Example: force re-render
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);
  

  const [loaded, setLoaded] = useState(false);

  function includeRazorpay() {

    const rzpScript = document.createElement('script');
    rzpScript.src = 'https://checkout.razorpay.com/v1/checkout.js';
    rzpScript.crossOrigin = 'anonymous';
    rzpScript.type = 'application/javascript';
    rzpScript.async = true;
    rzpScript.onload = () => {
      setLoaded(true);
    };
    document.body.appendChild(rzpScript);

    return rzpScript;

  }

  useEffect(() => {
    includeRazorpay()
  })
  useEffect(() => {
    const mobileView =
      new URLSearchParams(window.location.search).get("mobileContentPurchase") || undefined;
    if (mobileView) {
      razorPayRedirectToContent('SUCCESS', localStorage.getItem('userId') || '')
    }
  }, [])

  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const clientId = queryParams.get("clientId");
      const res = await API.user.getLoginSettings({ clientId });
      if (!API.validators.checkSuccessCode(res)) {
        return antdMessageError(res?.data?.message || "Failed to fetch data");
      } else {
        setLoginSetting(res.data);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const clientId = queryParams.get("clientId");
      const res = await API.user.getCheckoutSettings({ clientId });
      if (!API.validators.checkSuccessCode(res)) {
        return antdMessageError(res?.data?.message || "Failed to fetch data");
      } else {
        setCheckoutSetting(res.data);
      }
    })();
  }, []);
  
  return (
    <MyContext.Provider value={{
      brandLogoUrl: loginSetting?.loginCustomization?.brandLogoUrl,
      tnc: loginSetting?.loginCustomization?.redirects?.tnc,
      privPolicy: loginSetting?.loginCustomization?.redirects?.privPolicy,
      buttonBackground : checkoutSetting?.result?.customizeCTA?.backgroundColor,
      buttonFontColor: checkoutSetting?.result?.customizeCTA?.color,
    }}>
      <Router>
        <Routes>
          <Route path="/" element={<InitializaPayment />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/thankyou" element={<ThankyouNew />} />
        </Routes>
      </Router>
    </MyContext.Provider>
  );
}

export default App;
