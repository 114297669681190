import React, { useState } from "react";
import style from "./commonAntdSelectedBox.module.scss";
import { ReactComponent as DropDownArrow } from "../../assets/select_arrow_icon.svg";
import { ConfigProvider, Select } from "antd";
import CSS from "csstype";

const CustomDropDownForSelect = ({
  options,
  defaultValueForSelectOption,
  onChangeForSelect,
  onSearch,
  label,
  customStyle, 
  showSearch,
  dropdownStyle,
  placeholder,
  defaultValue,
  disabled,
  mode,
  filterOption,
  getPopupContainer,
  tagRender,
  maxTagPlaceholder,
  maxTagCount,
  hoverColor,
  theme,
  arrowIcon,
  onPopupScroll,
}: {
  options?: any;
  defaultValueForSelectOption?: any;
  onChangeForSelect?: any;
  onSearch?: any;
  label?: any;
  customStyle?: CSS.Properties;
  showSearch?: boolean;
  dropdownStyle?: CSS.Properties;
  placeholder?: string;
  defaultValue?: any;
  disabled?: boolean;
  mode?: any;
  filterOption?: any;
  getPopupContainer?: any;
  tagRender?: any;
  maxTagPlaceholder?: any;
  maxTagCount?: number;
  hoverColor?: string;
  theme?: any;
  arrowIcon?: any;
  onPopupScroll?: any;
}) => {
  const [hover, setHover] = useState(false);
  const hoverEffect = customStyle?.border
    ? hover
      ? `1px solid ${hoverColor ? hoverColor : '#0353e9'}`
      : customStyle?.border
    : "none";
  const selectOptions = (selectOptionItem: any, selectOptionLabel: any) => {
    let value = "";
    if (selectOptionLabel) {
      value = selectOptionLabel;
    } else {
      value = selectOptionItem.charAt(0).toUpperCase() + selectOptionItem.slice(1).toLowerCase();
    }
    return value;
  };
  return (
    <>
      <div
        style={{
          ...customStyle,
          border: hoverEffect,
        }}
        className={style["main-container"]}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <ConfigProvider
          theme={theme}
        >
          <Select
            mode={mode}
            filterOption={filterOption}
            showSearch={showSearch}
            placeholder={placeholder}
            tagRender={tagRender}
            maxTagPlaceholder={maxTagPlaceholder}
            className={style["common-select-box"]}
            dropdownStyle={dropdownStyle || { zIndex: 0 }}
            popupClassName={style["common-dropdown-select"]}
            suffixIcon={arrowIcon || <DropDownArrow />}
            value={defaultValueForSelectOption}
            // showArrow={true}
            onSearch={onSearch}
            onChange={onChangeForSelect}
            defaultValue={defaultValue}
            disabled={disabled}
            getPopupContainer={getPopupContainer}
            maxTagCount={maxTagCount}
            onPopupScroll={onPopupScroll}
          >
            {options?.array?.map((item: any, index: number) => {
              const selectOptionItem = options?.key
                ? item[`${options?.key}`]
                : item;
              const selectOptionLabel = label?.key
                ? label?.array[index][`${label?.key}`]
                : label?.array[index];
              return (
                <Select.Option
                  key={index}
                  value={selectOptionItem}
                  className={style["dropdown-options"]}
                >
                  {selectOptions(selectOptionItem, selectOptionLabel)}
                </Select.Option>
              );
            })}
          </Select>
        </ConfigProvider>
      </div>
    </>
  );
};
export default CustomDropDownForSelect;
