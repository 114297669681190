import React, { useEffect, useState } from "react";
import API from "../../Api/api";
import { makeRzpPayment } from "../makeRzpPayment";
import { razorPayRedirectToContent } from "../utills/mobilehandlers";

const RzpPayment = ({ accessToken ,clientName,userDetails}: { accessToken: string,clientName:string,userDetails:any }) => {

const arrayToken = accessToken.split('.');
const tokenPayload = JSON.parse(atob(arrayToken[1]));
localStorage.setItem('userId',tokenPayload.sub)
  const contentPassDetailsParam =
    new URLSearchParams(window.location.search).get("contentPassDetails") ||
    undefined;
  const contentPassDetails = JSON.parse(
    window.atob(contentPassDetailsParam as any)
  );
  const [loading, setLoading] = useState(false);

  const callRzpPayment = async () => {
    const addMoneyPageViewObject = {
      eventType: 'VIEW',
      eventLocation: 'CONTENT_FLOW_ADD_MONEY_PAGE',
      userId:tokenPayload.sub,
      contentId:contentPassDetails.clientContentId,
      clientId:  contentPassDetails.clientId,
      anonId: contentPassDetails.anonId,
      paywallId:contentPassDetails.paywallId,
    };
  try {
    navigator.sendBeacon(
      `${process.env.REACT_APP_KAFKA_URL}collect/event`,
      JSON.stringify({
        messageBody: [{ ...addMoneyPageViewObject }],
      }),
    );
  } catch (error) {
    console.error('error while sending events',error)
  }
    const rzrPayCrossBtn = () => {
      razorPayRedirectToContent('RZP_CROSS_BTN_CLICKED',tokenPayload.sub)
      const rzrPayCrossBtnObject = {
        eventType: 'CLICK',
        eventLocation: 'CONTENT_FLOW_ADD_MONEY_PAGE',
        clickAction: 'PAYMENT_GATEWAY_CROSS_BTN',
        userId:tokenPayload.sub,
        contentId:contentPassDetails.clientContentId,
        clientId: contentPassDetails.clientId,
        anonId:contentPassDetails.anonId,
        paywallId:contentPassDetails.paywallId,
      };
   try {
    navigator.sendBeacon(
      `${process.env.REACT_APP_KAFKA_URL}collect/event`,
      JSON.stringify({
        messageBody: [{ ...rzrPayCrossBtnObject }],
      }),
    );
   } catch (error) {
    console.error('error while sending events',error)

   }
      window.location.href = contentPassDetails.successUrl;
    };

 try {
  const pgDataresponse = await API.user.contentPassPurchase({
    clientId: contentPassDetails.clientId,
    clientContentId: contentPassDetails.clientContentId,
    type: contentPassDetails.type,
    userToken: accessToken,
    paywallType:contentPassDetails.paywallType,
    paywallId:contentPassDetails.paywallId,
  });
  const pgData = pgDataresponse.data.data;
  const redirectUrl = contentPassDetails.successUrl;
  const transactionid = pgData.transactionId;

  makeRzpPayment(
    pgData.rzpOrderId,
    pgData.color,
    pgData.logoUrl,
    userDetails.countryCode, 
    userDetails.phoneNumber,
    userDetails.email,
    rzrPayCrossBtn,
    setLoading,
    pgData.publishableKey,
    redirectUrl,
    transactionid,
    clientName
  )
 } catch (error) {
  //@ts-ignore
  if(error?.response?.data?.statusCode===403){
    window.location.href= contentPassDetails.successUrl;
  }
 }
  };
  useEffect(() => {
    callRzpPayment();
  }, []);

  return <div>{loading}</div>;
};

export default RzpPayment;
