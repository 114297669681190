import React, { useEffect, useState } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import API from "../../Api/api";
import style from "../stripePayment.module.css";

const StripePayment = ({
  accessToken,
  publishableKey,
}: {
  accessToken: string;
  publishableKey: string;
}) => {
  const [checkoutSessionSecret, setCheckoutSessionSecret] = useState<
    string | undefined
  >(undefined);
  const [finalRedirectUrl, setFinalRedirectUrl] = useState<string>();
  const contentPassDetailsParam =
    new URLSearchParams(window.location.search).get("contentPassDetails") ||
    undefined;
  const contentPassDetails = JSON.parse(
    window.atob(contentPassDetailsParam as any)
  );

  const initializeCheckout = async () => {
    try {
      const orderResponse = await API.user.stripeOrderCreate({
        clientId: contentPassDetails.clientId,
        clientContentId: contentPassDetails.clientContentId,
        type: contentPassDetails.type,
        redirectUrl: `${
          process.env.REACT_APP_API_URL_V2
        }/payment/gateway/rzpRedirect/?redirectUrl=${window.btoa(
          contentPassDetails.successUrl
        )}`,
        userToken: accessToken,
      });

      if (!API.validators.checkSuccessCode(orderResponse)) {
        console.error("Unable to fetch data");
        return;
      }

      setCheckoutSessionSecret(orderResponse.data?.data?.checkoutSessionSecret);
    } catch (error) {
      console.error("Error initializing checkout:", error);
    }
  };

  useEffect(() => {
    initializeCheckout();
  }, [accessToken]);

  useEffect(() => {
    if (checkoutSessionSecret) {
      const encodedRedirectUrl = encodeURIComponent(
        contentPassDetails.successUrl
      );
      setFinalRedirectUrl(encodedRedirectUrl);
    }
  }, [checkoutSessionSecret]);

  // Check if finalRedirectUrl is not yet available before rendering the component
  if (!finalRedirectUrl) {
    return (
      <div className={style["loading-container"]}>
        <div className={style["spinner"]}></div>
      </div>
    );
  }

  // Load stripePromise inside the component body
  const stripePromise = loadStripe(publishableKey);
  const crossBtnHandle = () => {
    window.location.href = decodeURIComponent(finalRedirectUrl);
  };
  return (
    <div className={style["container"]} id="checkout">
      <div className={style["cross-btn"]} onClick={crossBtnHandle}>
        X
      </div>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{ clientSecret: checkoutSessionSecret }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default StripePayment;
