import React, { useEffect, useState, useCallback } from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import API from "../../Api/api";
import paymentStyle from "../payment.module.css";
import Loader from "../loader";

export enum SUBSCRIPTION_ROUTES {
  REVIEW = "/review/subscription",
  THANKYOU = "/thankyou",
}

interface PaypalPaymentProps {
  accessToken: string;
  isContentPass?: boolean;
  publishableKey: string;
}

interface CreateOrderData {
  redirectUrl: string;
  transactionId: string;
}

const PaypalPayment: React.FC<PaypalPaymentProps> = ({
  accessToken,
  isContentPass,
  publishableKey,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [createOrderData, setCreateOrderData] =
    useState<CreateOrderData | null>(null);

  const getContentPassDetails = useCallback(() => {
    const contentPassDetailsParam = new URLSearchParams(
      window.location.search
    ).get("contentPassDetails");
    if (!contentPassDetailsParam) return null;
    try {
      return JSON.parse(window.atob(contentPassDetailsParam));
    } catch (e) {
      console.error("Error parsing content pass details:", e);
      return null;
    }
  }, []);

  const contentPassDetails = getContentPassDetails();

  useEffect(() => {
    if (!contentPassDetails) {
      setError("Invalid content pass details");
      setLoading(false);
      return;
    }
    contentPassDetails.accessToken = accessToken;
    contentPassDetails.successFrontendUrl = contentPassDetails.successUrl;
    contentPassDetails.failureFrontendUrl = contentPassDetails.failureUrl;
    contentPassDetails.redirectUrl = contentPassDetails.successUrl;

    const createOrder = async () => {
      try {
        const response = await API.payment.CallPaypal({
          callPaypalPrams: contentPassDetails,
        });
        if (!API.validators.checkSuccessCode(response)) {
          throw new Error("Unable to create PayPal order");
        }

        const { paypalOrderId, transactionId, paypalRedirectUrl } =
          response.data.data;
        setCreateOrderData({ redirectUrl: paypalRedirectUrl, transactionId });

        window.location.href = paypalRedirectUrl;
      } catch (err) {
        console.error("Error creating PayPal order:", err);
        setError("Unable to create PayPal order");
      } finally {
        setLoading(false);
      }
    };

    createOrder();
  }, [contentPassDetails, accessToken]);

  const onApprove = async () => {
    if (!createOrderData) return;

    try {
      const { transactionId } = createOrderData;
      const response = await API.user.transitionStatus({ id: transactionId });
      const status = response.data.data.status;

      if (status === "COMPLETE") {
        window.location.href = isContentPass
          ? window.location.href
          : SUBSCRIPTION_ROUTES.THANKYOU;
      }
    } catch (err) {
      console.error(
        "Error occurred while checking PayPal payment status:",
        err
      );
      setError("Error occurred while checking PayPal payment status");
    }
  };

  const onError = useCallback((err: any) => {
    console.error("PayPal Checkout onError:", err);
    setError("An error occurred with PayPal Checkout");
  }, []);

  const initialOptions = { clientId: publishableKey };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className={paymentStyle["main-container"]}>
        {loading && (
           <Loader />
        )}
        {error && <div className={paymentStyle["error-message"]}>{error}</div>}
      </div>
    </PayPalScriptProvider>
  );
};

export default PaypalPayment;
