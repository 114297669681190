import React from 'react'
import style from './checkout.module.scss'
import CommonModal from '../../UI/CommonModal/CommonModal'
import { Input } from 'antd'

export const ApplyCoupon = ({
  setIsModalOpen,
  isModalOpen
}: {
  setIsModalOpen: any;
  isModalOpen: any
}) => {
  return (
    <CommonModal
      isModalVisible={() => setIsModalOpen({ ...isModalOpen, applyCouponModal: false })}
      visible={isModalOpen?.applyCouponModal}
      centered
    >
      <div style={{ padding: '20px' }}>
        <div className={style['add-card-heading']}>Apply Coupon</div>
        <div style={{ marginTop: '16px' }}>
          <div className={style['address-name-input']} >
            <Input placeholder='Enter Your Coupon Code' />
          </div>
          <div className={style['address-list']}>
            {[1, 2, 3, 4, 5, 6].map(() => (
              <div>
                <div className={style['flex-justify-space-between']} style={{ margin: '25px 0px 12px', padding: '0px 18px' }}>
                  <div className={style['contact-heading-text']}>HGUGVGG655</div>
                  <div className={style['add-address']} style={{fontSize: '12px'}}>Apply</div>
                </div>
                <hr style={{ border: '0.9px solid rgba(0, 0, 0, 0.1)' }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </CommonModal>
  )
}