import axios from "axios";
import API from "../../Api/api";
import { message } from "antd";
import { makeRzpPayment } from "../makeRzpPayment";
interface DetailsType {
  [key: string]: {
    isRequired: boolean;
    mandatory: boolean;
  };
}
export interface CheckoutSettingsType {
  contactInfo: DetailsType;
  giftingDetails: DetailsType;
  deliveryAddress: {
    default: boolean;
    customization: DetailsType;
  };
  billingAddress: boolean;
  taxId: {
    taxIdInput: boolean;
    corporateMandatory: boolean;
    retailMandatory: boolean;
  };
  customizeCTA: CtaType;
}
export interface CtaType {
  backgroundColor: string;
  text: string;
  color: string;
  fontWeight: string;
  bold: boolean;
  fontStyle: string;
  italic: boolean;
}
export const checkoutSettingsDefaultData = {
  contactInfo: {
    nameInput: {
      isRequired: false,
      mandatory: false,
    },
    email: {
      isRequired: false,
      mandatory: false,
    },
    mobile: {
      isRequired: false,
      mandatory: false,
    },
    DOB: {
      isRequired: false,
      mandatory: false,
    },
    gender: {
      isRequired: false,
      mandatory: false,
    },
  },
  giftingDetails: {
    recipientName: {
      isRequired: false,
      mandatory: false,
    },
    recipientEmailPhone: {
      isRequired: false,
      mandatory: false,
    },
    message: {
      isRequired: false,
      mandatory: false,
    },
  },
  deliveryAddress: {
    default: true,
    customization: {
      physicalSubscription: {
        isRequired: false,
        mandatory: false,
      },
      digitalSubscription: {
        isRequired: false,
        mandatory: false,
      },
      adsFreeSubscription: {
        isRequired: false,
        mandatory: false,
      },
    },
  },
  billingAddress: true,
  taxId: {
    taxIdInput: false,
    corporateMandatory: false,
    retailMandatory: false,
  },
  customizeCTA: {
    backgroundColor: "",
    text: "",
    color: "",
    fontWeight: "",
    bold: false,
    fontStyle: "",
    italic: false,
  },
} as CheckoutSettingsType;

export const URLshortner = async (url: string) => {
  const data = await axios({
    url: `${process.env.REACT_APP_API_URL_V2}/url-shortner`,
    method: "POST",
    data: {
      url: url,
    },
  });
  return data.data;
};
export const callrzpSubscription = async (orderObj: any) => {
  const urlParams = new URLSearchParams(window.location.search);

// Remove the specific query parameter
urlParams.delete('siteUrl');

// Update the browser's URL without reloading the page
const newUrlSrp = window.location.origin + window.location.pathname + '?' + urlParams.toString();
// window.history.replaceState({}, '', newUrl);


  //  const findAccessToken = async () => {

  // setUserdata(userData.data);
  // setAccessToken(resp.data);
  // };
  // const data=await findAccessToken()
  function rzrPayCrossBtn() {
    orderObj.setLoading(false);
    window.location.reload()
  }
  // setLoading
  function setLoading() {
    console.log("kjdshkl");
  }
  const searchParams = new URLSearchParams(window.location.search);

  if (!orderObj.renew) {
    try {
      const cardExpiryMonth = orderObj.month;
      const cardExpiryYear = orderObj.year;
      const cardNumber = orderObj.cardNumber;
      if (orderObj.routing.gateway === "CYBERSOURCE") {
        const cyberSourceOrderResponse = await API.payment.cyberSourceOrder({
          manuallyRenewed: false,
          consumeFreeTrial: false,
          clientContentId: orderObj.clientContentId,
          successFrontendUrl: "https://www.google.com",
          failureFrontendUrl: "https://www.google.com",
          clientId: orderObj.clientId,
          subscriptionId: orderObj.SubscriptionId,
          anonId: orderObj.anonId,
          tierId: orderObj.tierId,
          type: orderObj.type,
          userDetails: orderObj.userDetails,
          userId: orderObj.userId,
          cyberSourceReferenceId: orderObj.cyberSourceSessionId,
          cardExpiryMonth,
          cardExpiryYear,
          cardNumber,
          tokenId: orderObj.cardToken,
          numberOfLicense: orderObj.numberOfLicense,
          couponCode:orderObj?.couponCode,
          clientReferenceId:searchParams.get('clientReferenceId')||""
        });
        // console.log(cyberSourceOrderResponse.data.status,'dfhsiodjoi',cyberSourceOrderResponse.data.data.status)
        // return
        const transactionId = cyberSourceOrderResponse.data.data.transactionId;
        if (cyberSourceOrderResponse.data.data.status === "AUTHORIZED") {
          const secondaryDetails =
          new URLSearchParams(window.location.search).get("secondaryDetails") || undefined;
          window.location.href = `${window.location.origin}/thankyou?transactionId=${transactionId}&clientContentId=${searchParams.get('clientContentId')}&clientId=${orderObj.clientId}&secondStep=${orderObj.secondStep}&thirdstep=${orderObj.thirdstep}&popUpId=${orderObj.popUpId}&secondaryDetails=${secondaryDetails}`;
        } else {
          antdMessageError(cyberSourceOrderResponse.data.data.error);
          // orderObj.setStepUpToken(cyberSourceOrderResponse.data.data.consumerAuthenticationInformation.accessToken)
          // orderObj.setTransactionId(cyberSourceOrderResponse.data.data.transactionId)
          // orderObj.setStepUrl('kjh')
          // orderObj.setShowStepUp(true)
          orderObj.setLoading(false);
          // antdMessageError('payment failed')
        }
        return;
      }
      const pgIntegrationResponse = await API.payment.pgIntegration({
        clientId: orderObj.clientId,
        subscriptionId: orderObj.SubscriptionId,
        anonId: orderObj.anonId,
        tierId: orderObj.tierId,
        type: orderObj.type,
        userDetails: orderObj.userDetails,
        userId: orderObj.userId,
        numberOfLicense: orderObj.numberOfLicense,
        amount: +orderObj.subsDetailsData.convertedPrice+orderObj.tax,
        currency: orderObj.subsDetailsData.currency,
        couponCode:orderObj.couponCode,
        clientReferenceId:searchParams.get('clientReferenceId')||'',
      });
      if (!API.validators.checkSuccessCode(pgIntegrationResponse)) {
        orderObj.setLoading(false);
        return;
      } else {
        const transactionid = pgIntegrationResponse?.data?.data?.transactionId;
        const pgData = pgIntegrationResponse.data.data;
        if (!pgData.rzpOrderId) return;
        // const failureUrl =
        const clientName = "";
        const secondaryDetails =
        new URLSearchParams(window.location.search).get("secondaryDetails") || undefined;
      


        const redirectUrl = `${window.location.origin}/thankyou?transactionId=${transactionid}&clientId=${orderObj.clientId}&clientContentId=${searchParams.get('clientContentId')}&secondStep=${orderObj.secondStep}&thirdstep=${orderObj.thirdstep}&popUpId=${orderObj.popUpId}&secondaryDetails=${secondaryDetails}&failureUrl=${window.btoa(newUrlSrp)}`;
// console.log(orderObj.userDetails.phoneNumber, orderObj.userDetails.secondaryPhone)
// console.log(orderObj.userDetails,'jhsfguisd')
const localPhone= orderObj.userDetails.phone? orderObj.userDetails.phone:orderObj.userDetails.secondaryPhone;
const localemail= orderObj.userDetails.email? orderObj.userDetails.email:orderObj.userDetails.secondaryEmail;
        makeRzpPayment(
          pgData.rzpOrderId,
          pgData.color,
          pgData.logoUrl,
          orderObj.userDetails.countryCode,
          // orderObj.userDetails.phoneNumber|| orderObj.userDetails.secondaryPhone,
          // orderObj.userDetails.email||orderObj.userDetails.secondaryEmail,
          localPhone,
          localemail,
          rzrPayCrossBtn,
          setLoading,
          pgData.publishableKey,
          redirectUrl,
          transactionid,
          clientName
        );
      }
    } catch (error: any) {
      orderObj.setLoading(false);
      if (error?.response) {
        if (error.response.status === 400 || error.response.status === 500) {
          antdMessageError(error.response.data.message);
        } else {
          antdMessageError(
            "An unexpected error occurred. Please try again later."
          );
        }
      } else {
        antdMessageError(
          "An unexpected error occurred. Please try again later."
        );
      }
    }
  } else {
   if(orderObj.routing.gateway ==='RAZORPAY')
    { const postreneweldata = await API.user.postRenewal({
      clientId: orderObj.clientId,
      subscriptionId: orderObj.SubscriptionId,
      anonId: orderObj.anonId,
      tierId: orderObj.tierId,
      type: orderObj.type,
      userDetails: orderObj.userDetails,
      userId: orderObj.userId,
      couponCode:orderObj.couponCode,
      clientReferenceId:searchParams.get('clientReferenceId')||''
    });
    const rzpSubscriptionId = postreneweldata.data.data.rzpSubscriptionId;
    const rzpSubscriptonNotes = postreneweldata.data.data.notes;
    const primaryColor = postreneweldata.data.data.color;
    const brandLogoUrl = postreneweldata.data.data.logoUrl;
    const transactionId = postreneweldata.data.data.renewalId;

    // const failureUrl = await URLshortner(
    //   subscriptionContentDetails?.failureFrontendUrl
    // );
    const secondaryDetails =
    new URLSearchParams(window.location.search).get("secondaryDetails") || undefined;
    const redirectUrl = `${window.location.origin}/thankyou?renewalId=${transactionId}&clientId=${orderObj.clientId}&secondStep=${orderObj.secondStep}&clientContentId=${searchParams.get('clientContentId')}&thirdstep=${orderObj.thirdstep}&secondaryDetails=${secondaryDetails}&failureUrl=${window.btoa(newUrlSrp)}`;
    const localRedirectUrl = window.btoa(redirectUrl);
    const options = {
      key: postreneweldata.data.data.publishableKey,
      subscription_id: rzpSubscriptionId,
      notes: {
        rzpSubscriptionId,
        ...rzpSubscriptonNotes,
      },
      name: "",
      image:
        brandLogoUrl ||
        "https://conscent-static-assets.s3.ap-south-1.amazonaws.com/assets/conscent+logo.png",
      prefill: {
        contact: `${orderObj.userDetails.countryCode}${orderObj.userDetails.phone||orderObj.userDetails.secondaryPhone}`,
        email: orderObj.userDetails.email||orderObj.userDetails.secondaryEmail,
      },
      redirect: true,
      modal: {
        confirm_close: false,
        ondismiss: function () {
         window.location.reload()
        },
      },
      callback_url: `${process.env.REACT_APP_API_URL}/payment/gateway/rzpRedirect/?redirectUrl=${localRedirectUrl}`,
      theme: {
        color: primaryColor || "#1a92b4",
      },
    };
    // @ts-ignore
    const rzp = new window.Razorpay(options);
    // setLoading?.();
    rzp.open();
    return
  }
    else{
      const cardExpiryMonth = orderObj.month;
      const cardExpiryYear = orderObj.year;
      const cardNumber = orderObj.cardNumber;
      const cyberSourceOrderResponse = await API.payment.cyberSourceOrderrenewal({
        manuallyRenewed: false,
        consumeFreeTrial: false,
        clientContentId: "Client-Story-Id-1",
        successFrontendUrl: "https://www.google.com",
        failureFrontendUrl: "https://www.google.com",
        clientId: orderObj.clientId,
        subscriptionId: orderObj.SubscriptionId,
        anonId: orderObj.anonId,
        tierId: orderObj.tierId,
        type: orderObj.type,
        userDetails: orderObj.userDetails,
        userId: orderObj.userId,
        cyberSourceReferenceId: orderObj.cyberSourceSessionId,
        cardExpiryMonth,
        cardExpiryYear,
        cardNumber,
        tokenId: orderObj.cardToken,
        numberOfLicense: orderObj.numberOfLicense,
        couponCode:orderObj.couponCode,
        clientReferenceId:searchParams.get('clientReferenceId')||'',
      });
      // console.log(cyberSourceOrderResponse.data.status,'dfhsiodjoi',cyberSourceOrderResponse.data.data.status)
      // return
      const transactionId = cyberSourceOrderResponse.data.data.transactionId;
      if (cyberSourceOrderResponse.data.data.status === "AUTHORIZED") {
        const secondaryDetails =
        new URLSearchParams(window.location.search).get("secondaryDetails") || undefined;
        window.location.href = `${window.location.origin}/thankyou?transactionId=${transactionId}&clientId=${orderObj.clientId}&secondStep=${orderObj.secondStep}&thirdstep=${orderObj.thirdstep}&popUpId=${orderObj.popUpId}&secondaryDetails=${secondaryDetails}&clientContentId=${searchParams.get('clientContentId')}`;
      } else {
        antdMessageError(cyberSourceOrderResponse.data.data.error);
        // orderObj.setStepUpToken(cyberSourceOrderResponse.data.data.consumerAuthenticationInformation.accessToken)
        // orderObj.setTransactionId(cyberSourceOrderResponse.data.data.transactionId)
        // orderObj.setStepUrl('kjh')
        // orderObj.setShowStepUp(true)
        orderObj.setLoading(false);
        // antdMessageError('payment failed')
      }
      return;
    }
  }
};
export function removeURLParam(param: string) {
  // Get the current URL
  const url = new URL(window.location.href);

  // Remove the specified parameter
  url.searchParams.delete(param);

  // Update the URL in the browser without reloading the page
  window.history.replaceState({}, document.title, url);
}

export const getWordsFromMonths = (months: number) => {
  let yearText = "";
  if (months >= 12)
    yearText =
      Math.floor(months / 12) === 1
        ? "1 year"
        : `${Math.floor(months / 12)} years`;

  const monthCount = months % 12;
  let monthText = "";
  if (monthCount) {
    monthText = yearText ? " & " : "";
    monthText += `${monthCount} month${monthCount > 1 ? "s" : ""}`;
  }
  const durationText = `${yearText}${monthText}`;

  return durationText;
};
export const antdMessageError = (content: string) => {
  return message.error({
    content: content,
    style: {
      marginTop: "130px",
    },
  });
};
export const antdMessageSuccess = (content: string) => {
  return message.success({
    content: content,
    duration: 2,
    style: {
      marginTop: '130px',
    },
  });
};

export const detectCardType = (number: string): string => {
  const cleanedNumber = number.replace(/\D/g, "");

  if (/^4/.test(cleanedNumber)) {
    return "001"; // VS (Visa)
  } else if (/^5[1-5]/.test(cleanedNumber)) {
    return "002"; // MS (MasterCard)
  } else if (/^3[47]/.test(cleanedNumber)) {
    return "003"; // AMEX (American Express)
  } else if (/^6(?:011|5)/.test(cleanedNumber)) {
    return "004"; // DS (Discover)
  } else if (/^35/.test(cleanedNumber)) {
    return "007"; // JCB
  } else if (/^3(?:0[0-5]|[68])/.test(cleanedNumber)) {
    return "005"; // DINER (Diners Club)
  } else {
    return "Unknown";
  }
};
