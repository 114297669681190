export function razorPayRedirectToContent(message?:string,userId?:string) {
    // for android
  
    try {
      // @ts-ignore
      redirectionHandler?.razorPayRedirectToContent(message,userId);
    } catch (error) {
      console.warn('did not find android redirect handler');
    }
  
    // for IOS
    try {
      // @ts-ignore
      window.webkit.messageHandlers.razorPayRedirectToContent.postMessage(JSON.stringify({ razorPayRedirectToContent: message,userId }));
    } catch (error) {
      console.warn('did not find ios redirect handler');
    }
  
    // for flutter-2-package
    try {
      // @ts-ignore
      window.flutter_inappwebview.callHandler('razorPayRedirectToContent', message,userId);
    } catch (error) {
      console.warn('did not find flutter 2 handler');
    }
  
    try {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({ razorPayRedirectToContent: message,userId }));
    } catch (error) {
      console.warn('did not find ReactNativeWebView handler');
    }
  }
  
  
  
  
  