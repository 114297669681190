import React, { useEffect, useState } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import API from "../../Api/api";
import style from "../stripePayment.module.css";
import Loader from "../loader";

const SubsStripePayment = ({

  subscriptionContentDetails,
}: {
 
  subscriptionContentDetails:any
}) => {
  const [gatewayPublishableKey, setGatewayPublishableKey] = useState<
    string | null
  >(null);
  const [checkoutSessionSecret, setCheckoutSessionSecret] = useState<
    string | undefined
  >(undefined);
  const [transactionId, setTransactionId] = useState<string>("");
  const [stripePromise, setStripePromise] = useState<any>(null);

// alert( subscriptionContentDetails.successFrontendUrl)
  const commonSubDetails = {
    subId: subscriptionContentDetails?.subscriptionId,
    tierId: subscriptionContentDetails?.tierId,
    amount: subscriptionContentDetails?.amount,
    currency: subscriptionContentDetails?.currency,
    clientId: subscriptionContentDetails?.clientId,
    contentId: subscriptionContentDetails?.clientContentId,
    landingPageId: subscriptionContentDetails?.landingPageId,
    landingPageTitle: subscriptionContentDetails?.landingPageTitle,
    userId: subscriptionContentDetails?.userId,
    anonId: subscriptionContentDetails?.anonId,
    paywallId: subscriptionContentDetails?.paywallId,
    paywallType: subscriptionContentDetails?.paywallType,
  };

  const initializeCheckout = async () => {
    // subscriptionContentDetails.accessToken = accessToken;
    const modifyRedirectUrl = new URL(
      subscriptionContentDetails.successFrontendUrl
    );
    modifyRedirectUrl.searchParams.append(
      "viewId",
      subscriptionContentDetails?.viewId
    );
    modifyRedirectUrl.searchParams.append(
      "clientId",
      subscriptionContentDetails?.clientId
    );
    modifyRedirectUrl.searchParams.append(
      "clientContentId",
      subscriptionContentDetails?.clientContentId
    );

    subscriptionContentDetails.redirectUrl = modifyRedirectUrl;
    const postRenewalPrams = {
      clientId: subscriptionContentDetails?.clientId,
      subscriptionId: subscriptionContentDetails?.subscriptionId,
      tierId: subscriptionContentDetails?.tierId,
      type: "SUBSCRIPTION",
      contentDetails: subscriptionContentDetails,
      couponCode: subscriptionContentDetails?.couponCode,
      consumeFreeTrial:
        subscriptionContentDetails?.consumeFreeTrial === undefined ||
        subscriptionContentDetails?.consumeFreeTrial === "undefined" ||
        subscriptionContentDetails?.consumeFreeTrial === null
          ? false
          : subscriptionContentDetails.consumeFreeTrial,
      anonId: subscriptionContentDetails?.anonId,
      redirectUrl: subscriptionContentDetails.redirectUrl,
    };
    try {
      if (subscriptionContentDetails?.renewSubscription) {
        const renewalOrderResponse =
          await API.payment.renewalSubscriptionOrderForStripe(postRenewalPrams);
        if (!API.validators.checkSuccessCode(renewalOrderResponse)) {
          console.error("Unable to fetch data");
          return;
        }
        setGatewayPublishableKey(
          renewalOrderResponse.data?.data?.publishableKey
        );
        setCheckoutSessionSecret(
          renewalOrderResponse.data?.data?.checkoutSessionClientSecret
        );
        setTransactionId(renewalOrderResponse.data?.data?.renewalId);
      } else {
        const orderResponse = await API.payment.subscriptionOrderForStripe(
          subscriptionContentDetails
        );
        if (!API.validators.checkSuccessCode(orderResponse)) {
          console.error("Unable to fetch data");
          return;
        }
        setGatewayPublishableKey(orderResponse.data?.data?.publishableKey);
        setCheckoutSessionSecret(
          orderResponse.data?.data?.checkoutClientSecret
        );
        setTransactionId(orderResponse.data?.data?.transactionId);
      }
    } catch (error) {
      console.error("Error initializing checkout:", error);
    }
  };

  useEffect(() => {
    initializeCheckout();
  }, []);

  useEffect(() => {
    if (gatewayPublishableKey) {
      setStripePromise(loadStripe(gatewayPublishableKey));
    }
  }, [gatewayPublishableKey]);

  const crossBtnHandle = async (transactionId: string) => {
    const rzrPayCrossBtnObject = {
      ...commonSubDetails,
      eventType: "CLICK",
      eventLocation: "SUBS_PAYMENT_GATEWAY",
      clickAction: "PAYMENT_GATEWAY_CROSS_BTN",
    };

    const abortResponse = await API.payment.abortTransaction({
      id: transactionId,
      type: subscriptionContentDetails?.renewSubscription ? "RENEWAL" : "ORDER",
      event: rzrPayCrossBtnObject,
    });
    if (!API.validators.checkSuccessCode(abortResponse)) {
      console.error("Unable to fetch data");
      return;
    } else {
      window.location.href = subscriptionContentDetails?.failureFrontendUrl;
    }
  };

  return (
    <>
      {checkoutSessionSecret ? (
        <div className={style["container"]} id="checkout">
          <div
            className={style["cross-btn"]}
            onClick={() => crossBtnHandle(transactionId)}
          >
            X
          </div>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret: checkoutSessionSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SubsStripePayment;
