import React from "react";
import style from "./CommonModal.module.scss"; 
import { Modal } from "antd";
import { ReactComponent as CrossIcon } from "../../assets/crossIcon.svg";

export default function CommonModal({
  children,
  customStyle,
  isModalVisible,
  visible,
  title,
  width,
  footer,
  centered,
}: {
  children?: any;
  customStyle?: any;
  isModalVisible?: any;
  visible?: boolean;
  title?: JSX.Element | string;
  width?: any;
  footer?: any;
  centered?: boolean;
}) {
  return (
    <Modal
      className={style["container"]}
      closable={true}
      centered={centered}
      style={customStyle || {}}
      open={visible}
      onCancel={isModalVisible}
      footer={footer || false}
      title={title}
      width={width}
      closeIcon={<CrossIcon />}
    >
      {children}
    </Modal>
  );
}
